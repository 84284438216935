<template>
  <div class="container">
    <div class="ewm page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준년</div>
              <DxDateBox
                styling-mode="outlined"
                :width="100"
                v-model="searchType.customTypes.vacationYear"
                type="date"
                date-serialization-format="yyyyMMdd"
                display-format="yyyy"
                date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
                maxLength="4"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'decade',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'decade',
                }"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년은 필수입니다." />
                </DxValidator>
              </DxDateBox>

              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
        <div class="mt-2 content-info-box01 space-x-6">
          <span class="text-xl">기준연차갯수: {{ config.vacationDayBase }}</span>
          <span class="text-xl">연차추가 범위(입사년차기준): {{ config.vacationDayRange }}</span>
        </div>
      </div>

      <esp-dx-data-grid :data-grid="dataGrid" ref="vacationSettingGrid" @saving="onSave" />

      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
          </div>
        </template>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: '120',
            height: '40',
            onClick: () => {
              isOpenModal(false);
            },
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import ModalUseList from '@/pages/ewm/hr/management/vacation-setting/modal-use-list.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxPopup,
      DxPosition,
      DxToolbarItem,

      DxRequiredRule,
      DxValidator,
      DxValidationGroup,

      ModalUseList,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            vacationYear: getPastFromToday(0, 'years'),
          },
          paramsData: {},
        },
        config: {
          vacationDayBase: null,
          vacationDayRange: null,
        },
        codes: {
          deptCd: {
            dataSource: [],
            displayExpr: 'pathNmFull',
            valueExpr: 'codeId',
          },
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        masterData: [],
        dataGrid: {
          keyExpr: 'id',
          refName: 'vacationSettingGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 370px)', // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            saving: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '연차자동적용',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 100,
                  height: 30,
                  onClick() {
                    vm.onAutoVacation();
                  },
                },
                location: 'before',
                sortIndex: 10,
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '부서정보',
              dataField: 'deptNmPath',
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowHeaderFiltering: true,
            },
            {
              caption: '상담사',
              dataField: 'agtId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              calculateSortValue: data => `${data.agtNm} [${data.agtId}]`,
              calculateDisplayCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtId}]`;
              },
            },
            {
              caption: '입사일',
              dataField: 'joinDt',
              width: 140,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '전년도 휴가사용/ 휴가일수',
              dataField: 'lastVacationData',
              width: 190,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.lastVacationUseCount} / ${rowData.lastVacationNumber + rowData.lastVacationEditNumber}`;
              },
            },
            {
              multiHeaderNm: '연차정보',
              columns: [
                {
                  caption: '자동계산',
                  dataField: 'vacationNumber',
                  width: 120,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                },
                {
                  caption: '값수정',
                  dataField: 'vacationEditNumber',
                  width: 120,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: true,
                  allowHeaderFiltering: true,
                  patternRule: {
                    //pattern: /^(0\.[0-9]{1,2}|[1-4]?\d(?:\.\d{1,2})?|50(?:\.0{1,2})?)$/,
                    pattern: /^-?(0|\d+(\.5)?)$/,
                    message: '값을 유효하게 입력하세요',
                  },
                  requiredRule: {
                    message: '필수 항목입니다.',
                  },
                },
                {
                  caption: '최종결과',
                  dataField: 'vacationCount',
                  width: 120,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                  calculateCellValue: rowData => {
                    return `${rowData.vacationNumber + rowData.vacationEditNumber}`;
                  },
                },
                {
                  caption: '사용연차',
                  dataField: 'vacationUseCount',
                  width: 100,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                  /* calculateCellValue: rowData => {
									return `${rowData.vacationUseCount}(${rowData.vacationUseZeroCount})`;
								}, */
                  cellTemplate: (container, options) => {
                    const aTag = document.createElement('a');
                    aTag.innerText = `${options.data.vacationUseCount} ( ${options.data.vacationUseZeroCount} )`;
                    aTag.addEventListener('click', () => {
                      vm.onUseData(options.data);
                    });
                    container.append(aTag);
                  },
                },
                {
                  caption: '잔여연차',
                  dataField: 'leastVacationCount',
                  width: 120,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                  calculateCellValue: rowData => {
                    return `${rowData.vacationNumber + rowData.vacationEditNumber - rowData.vacationUseCount}`;
                  },
                },
              ],
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      async onAutoVacation(e) {
        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        if (await this.$_Confirm('연차를 자동적용 하시겠습니까?')) {
          const payload = {
            actionname: 'EWM_HR_VACATION_SETTING_SAVE',
            data: {
              vacationYear: vm.searchType.customTypes.vacationYear.substring(0, 4),
              vacationDayBase: this.config.vacationDayBase,
              vacationDayRange: this.config.vacationDayRange,
            },
            loading: false,
          };
          const res = await vm.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.selectDataList();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },
      /** @description : 휴가사용내역 리스트 모달 */
      onUseData(data) {
        this.onOpenModal(
          'ModalUseList',
          {
            title: '휴가 사용 리스트',
            buttons: {
              cancel: { text: '닫기' },
            },
            width: 900,
            height: 500,
          },
          data,
        );
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      async onSave(e) {
        console.log(e.changes);
        if (!e.changes.length || !e.changes[0].data.vacationEditNumber) {
          this.$_Toast(this.$_msgContents('CMN_NO_CHANGED'));
          return;
        }

        if (
          !(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        let data = [];

        if (e.changes && e.changes.length > 0) {
          e.changes.forEach(d => {
            if (d.type == 'update') {
              let rowIndex = e.component.getRowIndexByKey(d.key);
              let row = e.component.getVisibleRows()[rowIndex];
              let updateData = row.data;

              data.push(updateData);
            } else if (d.type === 'insert') {
              if (Object.keys(d.data).length !== 0) {
                let updateData = { ...d.data };
                updateData['id'] = null;

                data.push(updateData);
              }
            }
          });
        }

        const payload = {
          actionname: 'EWM_HR_VACATION_SETTING_SAVE',
          data: { data },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        // if (!vm.searchType.customTypes.vacationYear) {
        // 	return;
        // }

        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        const payload = {
          actionname: 'EWM_HR_VACATION_SETTING',
          data: {
            vacationYear: vm.searchType.customTypes.vacationYear.substring(0, 4),
          },
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
        }
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.vacationYear) {
          return this.$_commonlib.formatDate(rowData.vacationYear, 'YYYY-MM-DD', 'YYYY');
        }
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        /* this.initCodeMap().then(() => {
				this.codes.deptCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4);

				this.$refs.vacationSettingGrid.getGridInstance.columnOption('deptCd', 'lookup', this.codes.deptCd);
			}); */

        this.config.vacationDayBase = this.$_getSystemData('ewm_hr_vacation_day_base')?.configValue;
        this.config.vacationDayRange = this.$_getSystemData('ewm_hr_vacation_day_range')?.configValue;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
